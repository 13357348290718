import React from 'react';
import {Select as AntdSelect, SelectProps as AntdSelectProps} from 'antd';

interface SelectProps extends AntdSelectProps {
    interval: string;
}

const Select: React.FC<SelectProps> = ({ interval, options = [], ...props }) => {
    const availableOptions = interval.split(';')
    const filteredOptions = interval ? options.filter((option) => availableOptions.includes(option.value as string)) : options
    
    return (
        <AntdSelect {...props} options={filteredOptions} />
    );
};

export default Select;
