import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FloatButton, Form, FormInstance, Spin } from 'antd';
import {debounce} from 'debounce'

import Survey, { getKey, getValue } from './components/Survey';
import { getData } from './api';

import './App.css';
import { LanguageContext } from './Layout';
import useSavingManager from './hooks/useSavingManager';

export const SurveyContext = React.createContext({savingKey: ''})
const isEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)

const getNewValue = (key: string, item: any, form: FormInstance) => {
  const oldValue = form.getFieldValue(key)
  const newValue = getValue(item)

  if (oldValue === undefined) {
    return newValue
  }

  return isEqual(oldValue, newValue) ? newValue : oldValue
}

function App() {
  const navigate = useNavigate()
  const language = useContext(LanguageContext)
  const [form] = Form.useForm()
  const [initialValues, setInitialValues] = useState<Record<string, any>>({})
  const [loading, setLoading] = useState(true)
  const [groups, setGroups] = useState<any[]>([])
  const ref = useRef<any>()

  useEffect(() => {
    setLoading(true)
    updateData().then(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateData = () => {
    return getData(language).then(({ data }) => {
        const newGroups = data.groups
        setGroups(newGroups)

        const items = newGroups.reduce((groupsAcc: any[], group: any) => ([...groupsAcc,
          ...group.subgroups.reduce((subgroupsAcc: any[], subgroup: any) => ([...subgroupsAcc, ...subgroup.data]), [])]), [])

        const newInitialValues = items.reduce((acc: any, item: any) => {
          const key = getKey(item)
          return { ...acc, [key]: getNewValue(key, item, form) }
        }, {})
        setInitialValues(newInitialValues)
        form.setFieldsValue(newInitialValues)
      }).catch(({response}) => {
        if (response.status === 401) {
          navigate('/login')
        }
      })
  }
  const {savingKey, updateItem} = useSavingManager(updateData)
  const debouncedUpdate = debounce(updateItem, 2000)

  const validate = (rule: any, value: string, _: any, debounce: boolean) => {
    const {field} = rule

    if (isEqual(initialValues[field], value)) {
      debouncedUpdate.clear()
      return
    }
    if (debounce) {
      debouncedUpdate([field, value])
    } else {
      debouncedUpdate.clear()
      updateItem([field, value])
    }
  }

  return (
    <Spin spinning={loading} size="large" style={{ overflow: 'hidden' }}>
      <SurveyContext.Provider value={{ savingKey }}>
        <div style={{ height: 'calc(100vh - 64px)', overflow: 'hidden auto', padding: '20px 20px' }} ref={ref}>
          {!loading && (
            <Survey
              initialValues={initialValues}
              form={form}
              groups={groups}
              validate={validate}
            />
          )}
          <FloatButton.BackTop target={() => ref.current || window} />
        </div>
      </SurveyContext.Provider>
    </Spin>
  );
}

export default App;
