import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { login } from './api';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate()

    const handleLogin = ({ username }: { username: string }) => {
        login(username).then(
            () => {
                localStorage.setItem('username', username)
                navigate('/')
            }
        ).catch(console.error)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 64px)' }}>
            <Form
                style={{width: 300}}
                onFinish={handleLogin}
            >
                <Form.Item name="username">
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"/>
                </Form.Item>
                <Button htmlType='submit' type='primary' style={{ width: '100%' }}>Log in</Button>
            </Form>
        </div>
    );
};

export default Login;
