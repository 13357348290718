import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { API_URL, deleteFile, uploadData } from '../api';

interface FileInputProps {
    variableKey: string,
    file?: string
}
const FileInput: React.FC<FileInputProps> = ({ variableKey, file }) => {
    const [internalFile, setInternalFile] = useState(file)
    const [hasError, setHasError] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const props = {
        action: `${API_URL}drill-file`,
        data: (File: any) => ({ VariableKey: variableKey, File }),
        customRequest: ({ data, file }: any) => {
            setInternalFile(file.name)
            setIsUploading(true)
            return uploadData(data)
                .then(() => setHasError(false))
                .catch(() => setHasError(true))
                .finally(() => setIsUploading(false))
        },
        onRemove: () => {
            setInternalFile('')
            deleteFile(variableKey)
        },
        fileList: internalFile ? [
            {
                uid: '1',
                name: internalFile,
                status: isUploading ? 'uploading' : hasError ? 'error' : 'done' as any,
                url: `${API_URL}drill-file/${variableKey}`,
            }
        ] : []
    }
    return (
        <Upload {...props}>
            {!internalFile && <Button>Upload (max 2MB)</Button>}
        </Upload>
    );
};

export default FileInput;
