import React, { useContext } from 'react';
import {Form, Tooltip, Tag, Spin} from 'antd';
import {DatabaseOutlined, UserOutlined, QuestionCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {getKey, isCalculatedValue, renderInput} from './Subgroup';
import { SurveyContext } from '../App';

const FormItem: React.FC<any> = ({ input, rules }) => {
    const interval = input.variableType === 3 && input.interval ? input.interval : ''
    const inputComponent = renderInput(input)
    const {savingKey} = useContext(SurveyContext)
    const isSaving = savingKey === input.key

    return (
        <Form.Item
            style={{ textAlign: 'left' }}
            key={input.key}
            rules={rules}
            validateTrigger={inputComponent.props['data-validateonblur'] ? ['onBlur', 'onPressEnter', 'onType'] : 'onChange'}
            label={<div style={{ display: 'flex', alignItems: 'center' }}>

                <div style={{ marginRight: '8px' }}>
                    {`${input.translatedKey} (${input.sortOrder})`}
                </div>
                {interval && <Tag>Interval: {interval}</Tag>}
                <Tooltip title={isCalculatedValue(input) ? "System calculated value" : "User defined value"}>
                    {(input.value || input.return) && (isCalculatedValue(input) ? <DatabaseOutlined style={{ marginRight: '10px' }} /> : <UserOutlined style={{ marginRight: '10px' }} />)}
                </Tooltip>
                <Tooltip title="Mandatory">
                    <div style={{
                        display: input.isMandatory ? 'block' : 'none',
                        borderRadius: '10px', backgroundColor: 'red',
                        height: '10px', width: '10px', marginRight: '10px'
                    }} />
                </Tooltip>
                <Tooltip title={JSON.stringify(input)}>
                    <QuestionCircleOutlined style={{ marginRight: '10px' }} />
                </Tooltip>
                <Spin spinning={isSaving} size='small' indicator={<LoadingOutlined />} style={{color: 'green'}} />
            </div>}
            name={getKey(input)}
            validateStatus={input.error ? 'error' : ''}
            help={(input.errors || []).map((error: any) => (<p style={{ marginTop: 0, marginBottom: 0 }} key={error.errorCode}>{error.errorMessage}</p>))}
        >
            {inputComponent}
        </Form.Item>
    )
}

export default FormItem;
