import React from 'react';
import { Tag, Tooltip, Row, Col } from 'antd';
import InputNumber from './InputNumber';

const ToleranceInput = ({ input, value, onChange, onBlur, onPressEnter, onType }: any) => {
    const props = { onBlur, onPressEnter }
    const getChangeHandler = (key: string) => (newValue: any) => {
        onChange({ ...value, [key]: newValue })
        onType({ ...value, [key]: newValue })
    }
    return (

        <Row gutter={[{xs: 4, md: 8}, {xs: 4, md: 8}]}>
            <Col xs={24} md={6}>
                <InputNumber value={value?.value} style={{ width: '100%' }} onChange={getChangeHandler('value')} {...props} />
            </Col>
            <Col xs={24} md={4}>
                <Tooltip placement='bottom' title={`Standard tolerance: ${input?.standardTolerance}`}>
                <Tag style={{ lineHeight: '30px', width: '100%', textAlign: 'center' }}>
                    Tol: {input?.standardTolerance}</Tag>
                </Tooltip>
            </Col>
            <Col xs={24} md={7}>
                <InputNumber value={value?.upperTolerance} addonBefore='Upper' style={{ width: '100%' }} onChange={getChangeHandler('upperTolerance')} {...props} />
            </Col>
            <Col xs={24} md={7}>
                <InputNumber value={value?.lowerTolerance} addonBefore='Lower' style={{ width: '100%' }} onChange={getChangeHandler('lowerTolerance')} {...props} />
            </Col>
        </Row>
    );
};

export default ToleranceInput;
