import React, { useState } from 'react';
import { Form } from 'antd';
import InputNumber from '../components/InputNumber';
import ToleranceInput from '../components/ToleranceInput';
import BooleanInput from '../components/BooleanInput';
import FileInput from '../components/FileInput';

const Inputs = () => {
    const [values, setValues] = useState({})
    return (
        <div style={{ padding: '30px' }}>
            <Form
                layout='vertical'
                onValuesChange={(_, allValues) => setValues(allValues)}
            >
                <Form.Item
                    name='boolean'
                    label='Boolean'
                >
                    <BooleanInput />
                </Form.Item>
                <Form.Item
                    name='number'
                    label='Number'

                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name='tolerance'
                    label='Tolerance'>
                    <ToleranceInput />
                </Form.Item>
                <Form.Item
                    name='file'
                    label='File'>
                    <FileInput variableKey='Workpiece_file' />
                </Form.Item>
                
            </Form>

            {JSON.stringify(values)}
        </div>
    );
};

export default Inputs;
