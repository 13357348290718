import React from 'react';
import { Segmented } from 'antd';

interface BooleanInputProps {
    value?: '1' | '0';
    onChange?: (newValue: string) => {}
}

const BooleanInput: React.FC<BooleanInputProps> = ({ value, onChange }) => {
    return <Segmented
        value={value}
        options={[
            { label: 'Not set', value: '' },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ]}
        onChange={(newValue) => onChange?.(newValue as string)}
    />
};

export default BooleanInput;
