import React from 'react';
import {Input as AntdInput, InputProps as AntdInputProps} from 'antd';

interface InputProps extends AntdInputProps {
    onType?: React.ChangeEventHandler
}

const Input: React.FC<InputProps> = (props) => {
    const onChange = (newValue: any) => {
        props.onChange?.(newValue)
        props.onType?.(newValue)
    }
    return (
        <AntdInput {...props}
            onChange={onChange}
        />
    );
};

export default Input;
