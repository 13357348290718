import React from 'react';
import { Collapse, Row, Col, Image } from 'antd';
import Title from 'antd/es/typography/Title';
import BooleanInput from './BooleanInput';
import ToleranceInput from './ToleranceInput';
import InputNumber from './InputNumber';
import FileInput from './FileInput';
import Select from './Select';
import Input from './Input';
import { BASE_API_URL } from '../api';
import FormItem from './FormItem';

enum DataType {
    BOOLEAN = "BOOLEAN",
    NUMBER = "NUMBER",
    VAR = "VAR",
    FILE = "FILE",
}

interface SurveySubgroupProps {
    subgroup: any;
    rules: any;
}

export const getKey = (input: any) => `${input.key}#-#${input.sortOrder}`

export const getValue = (item: any) => {
    const value = item.return !== '' ? item.return : item.value

    return item.isToleranceShown ? { value, lowerTolerance: item.lowerTolerance, upperTolerance: item.upperTolerance } : value
}

export const isCalculatedValue = (item: any) => {
    return item.return !== '' && item.return !== item.value
}


const getSegmentedImage = (src: string, label: string) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img width="80px" height="80px" src={src} alt={label} style={{ marginRight: '10px' }} />
            {label}
        </div>
    )
}

const Step_type_drill_images = {
        4: '/images/Step_type_drill_st2_Type1.gif',
        5: '/images/Step_type_drill_st2_Type2.gif',
        6: '/images/Step_type_drill_st2_Type5.gif',
        9: '/images/Step_type_drill_st2_Type6.gif',
        10: '/images/Step_type_drill_st2_Type9.gif',
        11: '/images/Step_type_drill_st2_Type17.gif',
        12: '/images/Step_type_drill_st2_Type18.gif'
}

const Images: Record<string, Record<string, string>> = {
    No_of_step_drill: {
        21: '/images/No_of_step_drill_1-step.gif',
        22: '/images/No_of_step_drill_2-step.gif'
    },
    Step_type_drill_st1: Step_type_drill_images,
    Step_type_drill_st2: Step_type_drill_images,
    Hole_type: {
        19: '/images/Hole_type_Bottom.gif',
        20: '/images/Hole_type_Through.gif'
    }
}

const getLabel = (lookupValue: any) => {
    if (lookupValue.translatedLookupName !== null && lookupValue.translatedLookupName !== undefined){
        return lookupValue.translatedLookupName;
    }
    return lookupValue.lookupName;
}

function renderLookupValues(input: any) {
    return <Select style={Images[input.key] ? {height: '100px'} : {}} interval={input.interval}
                   options={input.lookupValues.map((lookupValue: any) =>
                       ({
                           value: lookupValue.value,
                           label: Images[input.key]?.[lookupValue.value] ?
                               getSegmentedImage(Images[input.key][lookupValue.value],
                                   getLabel(lookupValue))
                               : getLabel(lookupValue)
                       }))}/>
}

export const renderInput = (input: any) => {
    const { dataType } = input

    switch (dataType) {
        case DataType.BOOLEAN:
            return <BooleanInput/>
        case DataType.NUMBER:
            if (input.lookupValues) {
                return renderLookupValues(input);
            }
            if (input.isToleranceShown) {
                return <ToleranceInput input={input} data-validateonblur />
            }

            return <InputNumber style={{ width: '100%' }} data-validateonblur />
        case DataType.VAR:

            if (input.lookupValues) {
                return renderLookupValues(input);
            } else {
                return <Input data-validateonblur />
            }
        case DataType.FILE:
            return <FileInput variableKey={input.key} file={input.embeddedFile} />
        default:
            return <Input data-validateonblur />
    }
}

const SurveySubgroup: React.FC<SurveySubgroupProps> = ({ subgroup, rules }) => {
    const title = subgroup.translatedSubgroupName
    const imagePath = subgroup.imagePath && `${BASE_API_URL}${subgroup.imagePath}`

    const image = imagePath ? <Image style={{maxWidth: '100%'}} alt={subgroup.title} src={imagePath } /> : null
    const formItems: JSX.Element[] = subgroup.data.map((input: any) => <FormItem input={input} rules={rules} />)
    const content = <Row gutter={4}>
        <Col xs={{span:24, order: 2 }} xl={{span: image ? 16 : 24, order: 1}}>
            {formItems}
        </Col>
        <Col xs={{span: 24, order: 1}} xl={{span: 8, order: 2}}>
            {image}
        </Col>
    </Row>

    if (title) {
        return <Collapse
            items={[{ key: title, label: <Title level={5}>{title}</Title>, children: content }]}
            defaultActiveKey={title}
            expandIconPosition='end'
        />
    }

    return content
};

export default SurveySubgroup;
