import React, { useState } from 'react';
import { Modal, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { uploadTemplate } from '../api';

interface UploadTemplateModalProps {
    open: boolean;
    onCancel: () => void;
}

const { Dragger } = Upload

const UploadTemplateModal: React.FC<UploadTemplateModalProps> = (props) => {
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const uploadProps = {
        maxCount: 1,
        customRequest: ({ file }: any) => setFile(file),
        onRemove: () => setFile(null),
        fileList: file ? [
            {
                uid: '1',
                name: file.name,
                status: 'done' as any
            }
        ] : []
    }
    const handleOk = () => {
        setLoading(true)
        uploadTemplate({ File: file })
            .then(() => {
                message.success('Template uploaded')
                props?.onCancel()
            })
            .catch(() => {
                message.error('Something went wrong. Try again')
                setLoading(false)
            })
    }

    return (
        <Modal
            title='Upload new template'
            {...props}
            okButtonProps={{ disabled: !file, loading }}
            onOk={handleOk}
        >
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag template to this area to upload</p>
                <p className="ant-upload-hint">
                    Uploading a new template will remove the existing one.
                </p>
            </Dragger>
        </Modal>
    );
};

export default UploadTemplateModal;
