import React from 'react';
import {InputNumber as AntdInputNumber, InputNumberProps as AntdInputNumberProps} from 'antd';
interface InputNumberProps extends AntdInputNumberProps {
    onType?: React.ChangeEventHandler
}

const InputNumber: React.FC<InputNumberProps> = (props) => {
    const onChange = (newValue: any) => {
        props.onChange?.(newValue)
        props.onType?.(newValue)
    }
    
    return (
        <AntdInputNumber {...props}
            onChange={onChange}
            type='number'
            controls={false}
        />
    );
};

export default InputNumber;
